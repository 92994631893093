import { http } from '@/http/axios.js'


export function listAPI(data) {
  return http({
    url: '/api/manage/vipProduct/list',
    method: 'POST',
    data
  })
}

export function addAPI(data) {
  return http({
    url: '/api/manage/vipProduct/add',
    method: 'POST',
    data
  })
}


export function editAPI(data) {
  return http({
    url: '/api/manage/vipProduct/edit',
    method: 'POST',
    data
  })
}


export function delAPI(data) {
  return http({
    url: '/api/manage/vipProduct/del',
    method: 'POST',
    data
  })
}



export function detailAPI(data) {
  return http({
    url: '/api/manage/vipProduct/detail',
    method: 'POST',
    data
  })
}



