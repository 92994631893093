<template>
    <el-dialog
        title="新增or修改"
        :visible="dialogVisible"
        :modal="false"
        width="30%"
        :before-close="handleClose">
        <el-form ref="form" label-width="80px" :rules="rules" :model="formData">
            <el-form-item label="名称" prop="title">
                <el-input v-model="formData.title"></el-input>
            </el-form-item>
            <el-form-item label="年" prop="year">
                <el-input v-model="formData.year" type="Number"></el-input>
            </el-form-item>
            <el-form-item label="价格" prop="price">
                <el-input v-model="formData.price"></el-input>
            </el-form-item>
            <el-form-item label="折扣" prop="discount">
                <el-input v-model="formData.discount"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="addOrEdit('form')">确定</el-button>
                <el-button @click="handleClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {addAPI, editAPI} from '../api'
export default {
    name: 'addOrEdit',
    data() {
        return {
            formData: this.vipProduct,
            rules: {
                title: [
                    {trigger: 'blur', message: '请输入名称', required: true}
                ],
                year: [
                    {validator: this.validateInteger, trigger: 'blur', required: true}
                ],
                price: [
                    {trigger: 'blur', message: '请输入价格', required: true},
                    { pattern: /^[0-9]+.?[0-9]{1,2}?$/ , message: '金额为数字', trigger: "blur"},
                ],
                discount: [
                    {trigger: 'blur', message: '请输入折扣', required: true},
                    { pattern: /^[0-9]+.?[0-9]{1,2}?$/ , message: '折扣为数字', trigger: "blur"},
                ],
            },
        }
    },

    props: {
        dialogVisible: {
            type: Boolean,
            default() {
                return 0
            },
        },
        vipProduct: {
            type: Object,
            default() {
                return {
                    id: 0,
                    title: '',
                    type: '',
                    price: 0,
                    year: 1,
                    discount: 0.0
                }
            }
        }
    },

    methods: {
        validateInteger(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入数字'));
            } else {
                const intValue = parseInt(value, 10);
                if (isNaN(intValue)) {
                    callback(new Error('请输入有效的整数'));
                } else if (intValue <= 0) {
                    callback(new Error('请输入大于0的整数'));
                } else {
                    callback();
                }
            }
        },

        handleClose() {
            this.$emit('close-form')
        },

        addOrEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                   if (this.formData.id) {
                       editAPI(this.formData).then(() => {
                           this.$message.success('编辑成功')
                           this.$emit('modify-success')
                       })
                   } else {
                       addAPI(this.formData).then(() => {
                           this.$message.success('新增成功')
                           this.$emit('modify-success')
                       })
                   }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>


<style scoped lang="scss">

</style>
