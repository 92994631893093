<template>
    <el-table :data="productList">
        <el-table-column prop="title" label="名称" min-width="120"></el-table-column>
        <el-table-column prop="row" label="价格" min-width="120">
            <template slot-scope="{ row }">
                {{ row.price }}元/{{ row.year }}年
            </template>
        </el-table-column>
        <el-table-column prop="discount" label="折扣率" min-width="120"></el-table-column>
        <el-table-column prop="ori_price" label="原价" min-width="120"></el-table-column>
        <el-table-column prop="year" label="年份" min-width="120"></el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="{ row }">
                <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">
                    编辑
                </el-link>
                <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import {delAPI} from '../api'

export default {
    name: 'items',
    props: {
        productList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        async handleDel({id}) {
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    delAPI({id}).then(() => {
                        this.$message.success('删除成功')
                        this.$emit('modify-success')
                    })
                })
                .catch(() => {
                    this.$message.info('取消删除')
                })
        },

        handleEdit(row) {
            this.$emit('handle-edit', row)
        }
    }
}
</script>


<style scoped lang="scss">

</style>
